<template>
  <div class="Comment">
        <van-nav-bar
            title="写评论"
            left-text="返回"
            left-arrow
            fixed
            placeholder
            @click-left="$router.go(-1)"
        >
        </van-nav-bar>
        <van-cell-group inset>
            <!-- 输入任意文本 -->
            <van-field v-model="text" label="姓名" />
            <van-field name="rate" label="评分">
                <template #input>
                    <van-rate v-model="value" color="#ffd21e"/>
                </template>
            </van-field>
            <van-field
                v-model="message"
                rows="1"
                autosize
                label="内容"
                type="textarea"
                placeholder="请输入留言"
            />
        </van-cell-group>
        <div style="margin: 16px;">
            <van-button round block color="#fd8412" native-type="submit" @click="Submit">
                提交
            </van-button>
        </div>
  </div>
</template>
<script>
import { defineComponent, ref, onMounted } from "vue";
import { useRouter } from "vue-router";
import { Toast } from 'vant';
import {com_comment_add_do,com_info_list_comment} from '@/api/J_comment'
export default {
    name:'Comment',
    setup(props) {
        let $route = useRouter();
        //评分及文本
        let value=ref('');
        let message=ref('');
        let text=ref('');
        let parameter={
            guid:$route.currentRoute.value.params.id,
            uname:'',
            good:'',
            content:''
        };
        
        let Dlist=ref([]);
        //方法
        //提交
        const Submit=()=>{
            parameter.good=value.value;
            parameter.content=message.value;
            parameter.uname=text.value;
            com_comment_add_do(parameter).then((res) => {
                if (res.data.statusCode=='200') {
                    Toast.success('添加成功');
                    setTimeout(()=>{
                        $route.go(-1);
                    },1000)
                };
            }).catch((err) => {
                console.log(err)
            });
        };
        //评论列表
        const Clist=()=>{
            com_info_list_comment().then((res) => {
                console.log('lb',res)
            }).catch((err) => {
                console.log(err)
            });
        };
        onMounted(() => {
            Clist()
        });
        return{
            value,
            message,
            text,
            Submit,
        }
    }
}
</script>
<style scoped lang="less">
//头部样式
/deep/ .van-icon-arrow-left{
  color: #323233;
}
/deep/ .van-nav-bar__text{
  color: #323233;
//   color: #fd8412;
}
// 输入框样式调整
/deep/ .van-cell-group--inset{
    margin-top: 2rem;
}
</style>